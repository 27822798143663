"use client";
import AppRouterErrorComponent from "@/components/AppRouterErrorComponent";
import { useRouter } from "next/navigation";

const NotFound = () => {
  const router = useRouter();
  return (
    <AppRouterErrorComponent
      refresh={router.refresh}
      error="It seems like you've reached a page that doesn't exist."
    />
  );
};

export default NotFound;
